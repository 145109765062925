<template>
  <div class="w-full h-full">
    <div
      class="flex flex-wrap justify-center mt-20 all"
    >
      <div class="marque w-1/6 ml-2 mr-4 mb-5" v-for="(item, index) in allInfo"
           :key="index">
        <marque-liste
          class="" :index="index" :icone="item" :position="position"
          @oga="retourMarque"
        />
      </div>
    </div>

    <div class="sel pl-5 pr-5 mt-5">
      <div class="w-full mr-3">
        <multiselect :option="autre" :write-option="true" :value="autreValue" @info="retourAutre"/>
      </div>
    </div>
  </div>
</template>

<script>
import marqueListe from '@/components/helper/form/marqueListe'
import toyota from '../../../assets/images/png/toyota.png'
import peugeot from '../../../assets/images/png/peugeot.png'
import mercedes from '../../../assets/images/png/mercedes.png'
import hyundai from '../../../assets/images/png/hyundai.png'
import honda from '../../../assets/images/png/honda.png'
import bmw from '../../../assets/images/png/bmw.png'
import renault from '../../../assets/images/png/renault.png'
import ford from '../../../assets/images/png/ford.png'
import multiselect from '@/components/helper/form/multiselect'
import faveCar from './vehiculeFirst.json'
import allCar from './cars.json'

export default {
  name: 'index',
  components: {
    marqueListe,
    multiselect
  },

  props: {
    donnes: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      allInfo: faveCar,
      autre: [],
      position: -1,
      value: '',
      autreValue: 'Autres'
    }
  },

  created () {
    this.trackImage()
  },

  methods: {
    retourMarque (answer) {
      this.position = answer
      this.value = this.allInfo[answer].name
      this.autreValue = 'Autres'
      this.$emit('info', this.value)
    },

    openOption () {
      this.activeOption = !this.activeOption
    },

    retourAutre (answer) {
      if (answer !== 'Autres') {
        this.autreValue = answer
        this.position = -1
        this.$emit('info', this.autreValue)
      }
    },

    trackImage () {
      for (const item in this.allInfo) {
        if (this.allInfo[item].name === 'Hyundai') {
          this.allInfo[item].image = hyundai
        }
        if (this.allInfo[item].name === 'Honda') {
          this.allInfo[item].image = honda
        }
        if (this.allInfo[item].name === 'Bmw') {
          this.allInfo[item].image = bmw
        }
        if (this.allInfo[item].name === 'Toyota') {
          this.allInfo[item].image = toyota
        }
        if (this.allInfo[item].name === 'Mercedes') {
          this.allInfo[item].image = mercedes
        }
        if (this.allInfo[item].name === 'Peugeot') {
          this.allInfo[item].image = peugeot
        }
        if (this.allInfo[item].name === 'Renault') {
          this.allInfo[item].image = renault
        }
        if (this.allInfo[item].name === 'Ford') {
          this.allInfo[item].image = ford
        }

        if (this.donnes !== null && this.donnes.marque !== undefined) {
          if (this.allInfo[item].name === this.donnes.marque) {
            this.position = parseInt(item)
          }
        }
      }

      for (const item in allCar) {
        this.autre.push(allCar[item].name)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/style/sass/variables";
.marque{
  width: 146.28px;
  height: 144.76px;
}
.sel{
  width: 99%;
}
.champ{
  background: #FFFFFF;
  border: 1px solid #DDDDDD;
  box-sizing: border-box;
  border-radius: 10px;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  font-family: $rubik;
  color: #909090;
  height: 70px;
}
@media screen and (max-width: 700px) {
  .marque{
    width: 100px;
    height: 121px;
  }
  .mt-20{
    margin-top: 40px !important;
  }
}
@media screen and (max-width: 769px) and (min-width: 701px){
  .mt-20{
    margin-top: 40px !important;
  }
}
@media screen and (max-width: 1024px) and (min-width: 770px){
  .mt-20{
    margin-top: 40px !important;
  }
}
</style>
