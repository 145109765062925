<template>
  <div class="w-full h-full">
    <div
      class="md:flex justify-center w-full pl-4 pr-4"
    >
      <radioAndIcon
        :index="item.name"
        :active="position"
        :icone="item.icon"
        v-for="(item, index) in option"
        :key="index"
        @radioStat="retourInfo"
        class="mt-6 md:w-1/2 w-full mr-1 ml-1"
      />
    </div>
  </div>
</template>

<script>
import radioAndIcon from '@/components/helper/form/radioAndIcon'

export default {
  name: 'index',
  components: {
    radioAndIcon
  },

  props: {
    donnes: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      option: [
        {
          name: 'Essence',
          icon: 'fuel'
        },
        {
          name: 'Gaz-oil',
          icon: 'gazoil'
        }
      ],
      position: 'Essence'
    }
  },

  mounted () {
    if (this.donnes.carburant !== undefined) {
      this.position = this.donnes.carburant
    }
    this.$emit('info', this.position)
  },

  methods: {
    retourInfo (answer) {
      this.$emit('info', answer)
      this.position = answer
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/style/sass/variables";
</style>
