<template>
  <div class="w-full h-full">
    <div
      class="flex justify-center mt-12"
    >
      <div
        class="ml-2 mr-4 mb-5 w-full pl-2"
      >
        <inputo type="number" placeholder="9" :value="puissance" @info="retourInfo"/>
      </div>
    </div>

    <div class="sous-titre md:w-1/3 w-2/3 text-center ml-auto mr-auto">
      La puissance fiscale est renseignée sur votre carte grise
    </div>
    <div class="text-center w-full flex justify-center">
      <svg-icon
        name="carte"
        class="md:h-56 h-48 mt-4"
        original
      />
    </div>
  </div>
</template>

<script>
import inputo from '@/components/helper/form/input'

export default {
  name: 'index',
  components: {
    inputo
  },

  props: {
    donnes: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      puissance: null
    }
  },

  mounted () {
    if (this.donnes.puissance !== undefined) {
      this.puissance = this.donnes.puissance
    }
  },

  methods: {
    retourInfo (answer) {
      this.puissance = answer
      if (this.puissance !== null) {
        this.$emit('info', this.puissance)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/style/sass/variables";
.input{
  background: #FFFFFF;
  border: 1px solid #E8E8E8;
  box-sizing: border-box;
  border-radius: 15.2377px;
  height: 70.09px;
}

div{
  font-family: $font-default;
}

.tiret{
  font-weight: 500;
  font-size: 20px;
  line-height: 34px;
  color: #A9B2C6;
  letter-spacing: 8.5px;
}

input, .neutre{
  font-weight: 500;
  font-size: 20px;
  line-height: 34px;
  color: #A9B2C6;
  letter-spacing: 4px;
}
.bas{
  margin-top: -10px;
}
.lettre{
  width: 8%;
}
.numero{
  width: 15%;
}
.sel{
  width: 99%;
}
</style>
