<template>
  <div
    class="w-full all h-full cursor-pointer flex items-center justify-center"
    @click="selectOption"
    :class="{'cocher': position === index}"
  >
    <div class="pt-5 pb-5">
      <div class="flex justify-center items-center mb-4 w-full">
        <img :src="icone.image" alt="" class="md:h-12 h-10">
      </div>
      <div v-if="label !== null" class="mb-4 pl-10 pr-10 text-center lab">{{ label }}</div>
      <div class="flex justify-center w-full">
        <svg-icon
            v-if="position === index"
            name="radio"
            class="h-4 mt-4"
            original
        />
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'index',
  props: {
    icone: null,
    label: null,
    position: null,
    index: null
  },
  data () {
    return {
    }
  },
  methods: {
    selectOption () {
      this.$emit('oga', this.index)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/style/sass/variables";
.all{
  border: 1px solid #E5E5E5;
  box-sizing: border-box;
  border-radius: 8px;
  background-color: #fff;
  cursor: pointer;
}
.svg-fill{
  fill: white;
}
.lab{
  font-weight: normal;
  font-size: 16px;
  line-height: 120%;
  text-align: center;
  color: #606060;
}
.cocher{
  border: 1px solid $base-green;
}
.all:hover{
  border: 1px solid $base-green;
}

</style>
