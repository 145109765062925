<template>
  <div class="w-full h-full">
    <div
      class="flex justify-center mt-12"
    >
      <div
        class="ml-2 mr-4 mb-5 w-full bg-white p-5 bord"
      >
        <div>
          <div class="denom mb-2">Type d’immatriculation</div>
          <multiselect :option="optionType" :value="valueType" @info="backType"/>
        </div>

        <div v-if="valueType === 'Provisoire'" class="mt-5">
          <div class="denom mb-2">Numero d’immatriculation</div>
          <inputo @info="retourPlaque" class="mt-2"/>
        </div>

        <plaque-input v-if="valueType === 'Définitive'" :donnes="donnes" @info="retourPlaque" class="mt-5"/>
      </div>
    </div>
  </div>
</template>

<script>
import plaqueInput from '@/components/helper/form/plaqueInput'
import multiselect from '@/components/helper/form/multiselect'
import inputo from '@/components/helper/form/input'

export default {
  name: 'index',
  components: {
    plaqueInput,
    multiselect,
    inputo
  },

  props: {
    donnes: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      optionType: ['Provisoire', 'Définitive'],
      valueType: 'Provisoire'
    }
  },

  methods: {
    retourPlaque (answer) {
      this.$emit('info', answer)
      if (this.valueType === 'Provisoire') {
        this.$emit('type', true)
      } else {
        this.$emit('type', false)
      }
    },

    backType (s) {
      this.valueType = s
      if (this.valueType === 'Provisoire') {
        this.$emit('type', true)
      } else {
        this.$emit('type', false)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/style/sass/variables";

div{
  font-family: $font-default;
}

.sel{
  width: 99%;
}
.denom {
  color: #A9B2C6;
}
.bord {
  border: 1px solid #E8E8E8;
  border-radius: 15.2377px;
}

</style>
