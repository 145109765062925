<template>
  <div class="w-full h-full">
    <div
      class="flex flex-wrap justify-center mt-12"
    >
      <div v-for="(item, index) in allPlace" :key="index" class="mr-2 ml-3 mb-4">
        <svg-icon
          name="seet-place"
          class="h-16"
          original
        />
      </div>
    </div>

    <div class="flex items-center justify-center mt-6 pt-2">
      <div class="flex justify-center items-center rounded-full md:h-16 md:w-16 w-12 h-12 moins mr-6 cursor-pointer" @click="moins">
        <div class="text-black">-</div>
      </div>

      <div class="inp flex items-center justify-center mr-6">
        <div>{{allPlace.length}}</div>
      </div>

      <div class="flex justify-center items-center rounded-full md:h-16 md:w-16 w-12 h-12 plus cursor-pointer" @click="plus">
        <div class="text-white">+</div>
      </div>
    </div>

  </div>
</template>

<script>

export default {
  name: 'index',
  components: {
  },

  props: {
    donnes: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      allPlace: ['', '', '', '', '']
    }
  },

  mounted () {
    if (this.donnes.place !== undefined) {
      const tab = []
      let i = 0
      while (i < this.donnes.place) {
        tab.push('')
        i++
      }
      this.allPlace = tab
    }
    this.$emit('info', this.allPlace.length)
  },

  methods: {
    moins () {
      if (this.allPlace.length > 1) {
        this.allPlace.splice(0, 1)
        this.$emit('info', this.allPlace.length)
      }
    },

    plus () {
      this.allPlace.push('')
      this.$emit('info', this.allPlace.length)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/style/sass/variables";
.inp{
  background: #FFFFFF;
  border: 2.4797px solid #E8E8E8;
  box-sizing: border-box;
  border-radius: 30.9963px;
  height: 77.49px;
  width: 20%;
  font-weight: normal;
  font-size: 37.1956px;
  line-height: 41px;
  color: #02548B;
}

div{
  font-family: $font-default;
}
.plus{
  background-color: $base-color;
}
.plus:hover{
  background-color: $hover-button;
}
.moins{
  background-color: #E8E8E8;
}
.moins:hover{
  border: .2px solid $hover-button;
}
.rounded-full{
  font-weight: normal;
  font-size: 37.1956px;
  line-height: 41px;
  text-align: center;
  color: #000000;
}

@media screen and (max-width: 700px){
  .inp{
    width: 40%;
    height: 59.96px;
    border-radius: 23.9853px;
    font-size: 28px;
  }
}
</style>
