<template>
<div class="about-page w-full h-screen pt-10">
  <div class="barx mr-auto ml-auto" v-if="position < 8">
    <progress-bar :width="totalPourcent"/>
  </div>

  <div class="titre mt-5 pt-1" v-if="position < 8 || position > 9">
    <span v-if="position > 0">{{allQuiz[position-1].name}}</span>
    <span v-if="position === 0">
      Catégorie de votre véhicule
    </span>
  </div>

  <steep0 v-if="position === 0" @info="retourPrel"/>

  <steep1 :donnes="responses" v-if="position === 1" @info="retourMarque"/>

  <steep2 v-if="position === 2" :donnes="responses" @info="retourModel"/>

  <div v-if="position === 3">
    <steep3 :donnes="responses" @info="retourPlaque" @type="backPlaqueType"/>

    <div class="titre mt-5 pl-10 pr-10">
      {{allQuiz[position-1].second}}
    </div>

    <steep3Second :donnes="responses" @info="retourPuissance"/>
  </div>

  <div v-if="position === 4">
    <steep4 :donnes="responses" @info="retourCarburant"/>

    <div class="titre mt-10 pl-10 pr-10" v-if="allQuiz[position-1]">
      {{allQuiz[position-1].second}}
    </div>

    <steep4Second :donnes="responses" @info="retourZone"/>
  </div>

  <steep5 :donnes="responses" v-if="position === 5" @info="retourProtection"/>

  <steep6 :donnes="responses" v-if="position === 6" @info="retourStatus"/>

  <steep7 :donnes="responses" v-if="position === 7" @info="retourOldAssur" @date="retourDelivrance" @dure="retourDure"/>

  <div class="spn pt-6 mt-6" v-if="position === 8 && !activeDevis">
    <div class="w-full flex justify-center items-center h-32">
      <spinner />
    </div>

    <div class="mt-10 text-center textCharge">
      Calcul de la prime...
    </div>
  </div>

  <div class="spn" v-if="position === 8 && activeDevis">
    <div class="w-full flex justify-center items-center h-full">
      <devis-icons />
    </div>
  </div>

  <div class="resultat md:pl-10 md:pr-10">
    <resultatPrime v-if="position === 9" type="Auto" @retour="retourDevis"/>
  </div>

  <steep8 :donnes="responses" v-if="position === 10" @info="retourPermis"/>

  <steep9 :donnes="responses" v-if="position === 11" @info="retourPlace"/>

  <valeurVehicule :donnes="responses" v-if="position === 12" @info="retourValeur"/>

  <proprietaire :donnes="responses" v-if="position === 13" @info="retourUtilisateur"/>

  <div class="error mt-4 text-center" v-if="error !== null && yetError !== null">{{error}}</div>
  <div id="error"></div>
  {{donnee}}
</div>
</template>

<script>
import steep1 from '@/components/cedro/automobile/steep1'
import steep0 from '@/components/cedro/automobile/steep0'
import progressBar from '@/components/helper/add/progressBar'
import steep2 from '@/components/cedro/automobile/steep2'
import steep3 from '@/components/cedro/automobile/steep3'
import allQuiz from '@/components/cedro/automobile/allQuiz.json'
import steep3Second from '@/components/cedro/automobile/steep3Second'
import steep4 from '@/components/cedro/automobile/steep4'
import steep4Second from '@/components/cedro/automobile/steep4Second'
import steep5 from '@/components/cedro/automobile/steep5'
import steep6 from '@/components/cedro/automobile/steep6'
import steep7 from '@/components/cedro/automobile/steep7'
import steep8 from '@/components/cedro/automobile/permi'
import spinner from '@/components/helper/add/spinner'
import devisIcons from '@/components/helper/add/devisIcons'
import resultatPrime from '@/components/cedro/devis/devisTemplate'
import steep9 from '@/components/cedro/automobile/places'
import valeurVehicule from '@/components/cedro/automobile/valeur'
import proprietaire from '@/components/cedro/automobile/proprietaire'
import http from '@/plugins/http'
import apiroutes from '@/router/api-routes'
import locality from '@/components/cedro/jsonWord/locality.json'
import status from '@/components/cedro/jsonWord/social-status.json'

export default {
  name: 'index',
  components: {
    steep1,
    steep0,
    progressBar,
    steep2,
    steep3,
    steep3Second,
    steep4,
    steep4Second,
    steep5,
    steep6,
    steep7,
    steep8,
    spinner,
    devisIcons,
    resultatPrime,
    steep9,
    valeurVehicule,
    proprietaire
  },
  props: {
    width: {
      type: String,
      default: '30%'
    },
    retour: {
      type: Number,
      default: null
    },
    suivant: {
      type: Number,
      default: null
    }
  },
  data () {
    return {
      position: 1,
      allQuiz,
      temoinR: 0,
      temoinS: 0,
      activeDevis: false,
      responses: {
        type: 'Auto'
      },
      error: null,
      yetError: null,
      totalPourcent: '0%',
      preliminaire: null
    }
  },

  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    donnee: function () {
      this.trackPosition(this.retour, this.suivant)
    }
  },

  created () {
    this.position = this.$store.getters.trackPosition
    if (this.position === 9) {
      this.position = 10
    }
    if (this.position >= 9) {
      this.responses = this.$store.getters.trackFirstDevis
      // console.log(this.responses)
    }
    this.$store.dispatch('product', 'automobile')
    if (this.position > 13) {
      this.$router.push('/recapitulatif')
    }
    this.$store.dispatch('saveSouscription', null)
  },

  methods: {
    retourPrel (answer) {
      this.preliminaire = answer
    },

    backPlaqueType (a) {
      this.responses.provisoir = a
      console.log(a)
    },

    retourDevis (answer) {
      this.position = 7
      setTimeout(() => {
        this.error = null
      }, 200)
    },

    retourDelivrance (answer) {
      this.responses.permisDate = answer
    },

    retourDure (answer) {
      this.responses.dure = answer
    },

    retourUtilisateur (answer) {
      this.responses.user = answer
      this.$store.dispatch('saveFirstDevis', this.responses)
    },

    devisRequest () {
      let old = false
      let garantie = 'IndividuelleConducteur'
      const operation = 'new'
      let zone = 'v'
      let statusSocial = 's'
      let classe = 'a'

      if (this.responses.oldAssur.toLowerCase() === 'oui') {
        old = true
        // operation = 'renew'
      }

      if (this.responses.protection === 'oui') {
        garantie = 'ProtectionChauffeur'
      }

      for (const item in locality) {
        if (locality[item].label === this.responses.zone) {
          zone = locality[item].zone
        }
      }

      for (const item in status) {
        if (status[item].label === this.responses.socioStatus) {
          statusSocial = status[item].status
        }
      }

      const dat = new Date(this.responses.permisDate).getFullYear()
      const actu = new Date().getFullYear()
      const dure = actu - dat
      if (dure > 2) {
        classe = 'b'
      }
      let fuel = 'e'
      this.$store.dispatch('saveFirstDevis', this.responses)
      if (this.responses.carburant.substr(0, 1).toLowerCase() !== 'e') {
        fuel = 'd'
      }

      const body = {
        power: this.responses.puissance,
        period: this.responses.dure.toLowerCase().replace(/ /g, ''),
        guaranties: garantie,
        operation: operation,
        fuel: fuel,
        zone: zone,
        social: classe,
        status: statusSocial,
        hasOldCertificate: old
      }
      // console.log('mon body')
      // console.log(body)
      http.post(apiroutes.baseURL + apiroutes.primeAuto, body)
        .then(response => {
          // console.log('devis', response.data)
          const lastSave = this.$store.getters.trackFirstDevis
          lastSave.devis = response.data
          lastSave.createdAt = new Date().getTime()
          lastSave.id = 'DV-00' + (this.$store.getters.allDevis.length + 1)
          this.$store.dispatch('saveFirstDevis', lastSave)
          // setTimeout(() => {
          this.activeDevis = true
          setTimeout(() => {
            this.position = 9
            this.activeDevis = false
            this.$emit('steep', 'resultat')
          }, 500)
          // }, 5000)
        })
        .catch(error => {
          console.log('erreur')
          console.log(error)
        })
    },

    retourPermis (answer) {
      this.responses.permis = answer
    },

    retourPlace (answer) {
      this.responses.place = answer
    },

    retourValeur (answer) {
      this.responses.valeur = answer
    },

    retourPuissance (answer) {
      this.responses.puissance = answer
      this.error = null
    },

    retourOldAssur (answer) {
      this.responses.oldAssur = answer
      this.error = null
      this.$store.dispatch('saveFirstDevis', this.responses)
    },

    retourZone (answer) {
      this.responses.zone = answer
      this.error = null
    },

    retourMarque (answer) {
      this.responses.marque = answer
      this.error = null
    },

    retourStatus (answer) {
      this.responses.socioStatus = answer
      this.error = null
    },

    retourCarburant (answer) {
      this.responses.carburant = answer
      this.error = null
    },

    retourPlaque (answer) {
      this.responses.plaque = answer
      console.log(answer)
      this.error = null
    },

    retourModel (answer) {
      this.responses.model = answer
      this.error = null
    },

    retourProtection (answer) {
      this.responses.protection = answer
      this.error = null
    },

    // traquer la position du remplissage du formulaire
    trackPosition (retour, suivant) {
      this.error = null

      if (suivant !== this.temoinS) {
        this.yetError = 'ok'
      }
      if (this.position > 1 && retour !== this.temoinR) {
        this.position = this.position - 1
        this.temoinR = retour

        setTimeout(() => {
          this.error = null
        }, 200)
      } else if (suivant !== this.temoinS && this.errorTrack() !== null) {
        if (this.position === 0) {
          if (this.preliminaire !== null && this.preliminaire.couverture !== undefined) {
            this.yetError = null
            this.position = this.position + 1
            this.temoinS = suivant
          } else {
            this.error = 'Veuillez selectionner une option pour continuer'
          }
        } else {
          this.yetError = null
          this.position = this.position + 1
          this.temoinS = suivant
        }
      } else {
        if (this.position < 8) { this.error = 'Veuillez entrer une donnée valide pour continuer' }
      }
      this.trackSteep()

      this.responses.position = this.position

      this.$store.dispatch('position', this.position)
      const pourcent = (this.position * 100) / 8
      this.totalPourcent = pourcent.toString() + '%'
    },

    // traquer les champs non remplis
    errorTrack () {
      if (this.position === 2) {
        if (this.responses.model !== undefined && this.responses.model !== 'Autres') {
          return 'ok'
        } else {
          return null
        }
      } else if (this.position === 1) {
        if (this.responses.marque !== undefined) {
          return 'ok'
        } else {
          return null
        }
      } else if (this.position === 3) {
        // ici je vérifie si la plaque est provisoire ou pas avant de verifier le contenue des champs
        if (this.responses.provisoir === true) {
          if (this.responses.plaque !== undefined && this.responses.puissance !== undefined && this.responses.puissance !== '') {
            return 'ok'
          } else {
            return null
          }
        } else {
          if (this.responses.plaque !== undefined && this.responses.puissance !== undefined && this.responses.plaque.length >= 7 && this.responses.puissance !== '') {
            return 'ok'
          } else {
            return null
          }
        }
      } else if (this.position === 4) {
        if (this.responses.carburant !== undefined && this.responses.zone !== undefined && this.responses.zone !== '') {
          return 'ok'
        } else {
          return null
        }
      } else if (this.position === 7) {
        if (this.responses.permisDate !== undefined && this.responses.permisDate !== null && this.responses.permisDate !== '') {
          return 'ok'
        } else {
          return null
        }
      } else if (this.position === 10) {
        if (this.responses.permis.numero !== undefined && this.responses.permis.picture) {
          return 'ok'
        } else {
          return null
        }
      } else if (this.position === 12) {
        if (this.responses.valeur.valeurVehicule !== null && this.responses.valeur.souscriptionDate !== null && this.responses.valeur.souscriptionDate !== undefined) {
          return 'ok'
        } else {
          return null
        }
      } else if (this.position === 13) {
        if (this.responses.user !== undefined) {
          return 'ok'
        } else {
          return null
        }
      } else {
        return 'ok'
      }
    },

    trackSteep () {
      if (this.position === 7) {
        this.$emit('steep', 'prime')
      }

      if (this.position < 7) {
        this.$emit('steep', 'continuer')
      }

      if (this.position === 8) {
        this.devisRequest()
        this.$emit('steep', 'charge')
      }

      if (this.position > 9) {
        this.$emit('steep', 'afterAnswer')
      }

      if (this.position === 14) {
        this.$router.push('/recapitulatif')
      }
    }

  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/style/sass/variables";

.about-page{
  width: 50%;
  margin-right: auto;
  margin-left: auto;
  font-family: $font-default;
  background-color: #F5F5F5;
  height: 100%;
  padding-bottom: 300px;
}
.barx{
  width: 95%;
}
.spn{
  width: 100%;
  height: 400px;
}

  .titre{
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 64px;
    text-align: center;
    color: #000000;
  }
  .textCharge{
    font-weight: bold;
    font-size: 28px;
    line-height: 153%;
    color: #263238;
  }
  .num{
    font-weight: bold;
    font-size: 64px;
    line-height: 34px;
    color: rgba(2, 105, 173, 0.1);
  }
  .sous{
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;
    color: #49BF7C;
  }
  .error{
    color: #e36767;
    font-size: 18px;
    line-height: 32px;
  }

  @media screen and (max-width: 1024px) and (min-width: 770px){
    .about-page {
      width: 70%;
      padding-bottom: 200px;
    }
  }

  @media screen and (max-width: 769px) and (min-width: 701px){
    .about-page {
      width: 90%;
      padding-bottom: 200px;
    }
  }

  @media screen and (max-width: 700px) {
    .about-page {
      width: 90%;
      padding-bottom: 200px;
    }
    .titre{
      font-size: 24px;
      line-height: 32px;
    }
    .textCharge{
      font-size: 20px;
    }
  }

</style>
