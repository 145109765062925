<template>
  <div class="w-full h-full">
    <div
      class="flex justify-center mt-12"
    >
      <div
        class="ml-2 mr-4 mb-1 w-full pl-2 "
      >
        <div
          v-for="(item, index) in allInfo"
          :key="index"
          class="w-full mb-3"
        >
          <radio
            v-if="index < 5"
            :index="item"
            :active="position"
            @radioStat="retourInfo"
            class="w-full"
          />
        </div>

      </div>
    </div>

    <div class="sel pl-4 pr-2">
      <multiselects
        :option="allInfo"
        :write-option="true"
        class="w-full"
        @info="retourInfo"
      />
    </div>
  </div>
</template>

<script>
import radio from '@/components/helper/form/radioAndLabel'
import multiselects from '@/components/helper/form/multiselect'
import allCar from './cars.json'

export default {
  name: 'index',
  components: {
    multiselects,
    radio
  },
  props: {
    donnes: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      allInfo: ['EQA', 'C 300', 'Classe S', 'Classe G', 'GLE Coupé'],
      position: 0
    }
  },
  created () {
    this.trackModel()
    if (this.donnes.model !== undefined) {
      this.position = this.donnes.model
    }
  },
  methods: {
    retourInfo (answer) {
      if (answer !== 'Autres') {
        this.position = answer
        this.$emit('info', answer)
      }
    },

    trackModel () {
      for (const item in allCar) {
        if (allCar[item].name === this.donnes.marque) {
          this.allInfo = allCar[item].models
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/style/sass/variables";
.sel{
  width: 99%;
}
</style>
