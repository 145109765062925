<template>
  <div
    class="radio flex items-center p-4 md:pl-4 md:pr-4 pl-5 pr-5"
    :class="{'cocher': active === index}"
  >
    <div @click="activer()" class="flex w-full h-auto cursor-pointer items-center">
      <svg-icon
        v-if="icone !== null"
        :name="icone"
        class="h-20 mr-5"
        original
      />

      <div class="w-full lab md:ml-0 ml-2">{{ index }}</div>

      <svg-icon
        v-if="active === index"
        name="radio"
        class="ico"
        original
      />

      <svg-icon
        v-if="active !== index"
        name="radio-off"
        class="ico"
        original
      />
    </div>
  </div>
</template>

<script>

export default {
  name: 'Index',
  components: {
  },
  props: {
    active: {
      type: String,
      default: null
    },
    index: {
      type: String,
      default: ''
    },
    icone: {
      type: String,
      default: ''
    }
  },
  data () {
    return {

    }
  },
  created () {
  },
  methods: {
    activer () {
      this.active = this.index
      this.$emit('radioStat', this.active)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/style/sass/variables";
.radio{
  background: #FFFFFF;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  height: 111px;
}
.lab{
  font-weight: normal;
  font-family: 'Mulish', sans-serif;
  font-size: 22px;
  line-height: 28px;
  color: #191919;
}
.ico{
  height: 1.7rem;
}
.svg-fill{
  fill: white;
}
.radio:hover{
  border: 1px solid $base-green;
}
.cocher{
  border: 1px solid $base-green;
}
</style>
