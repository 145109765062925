<template>
  <div class="w-full h-full">
    <div
      class="flex justify-center mt-6"
    >
      <div
        class="ml-2 mr-4 mb-5 w-full pl-2"
      >
        <inputo :value="zone" @info="retourInfo" placeholder="Écrivez pour rechercher"/>
        <selectListe :option="allZone" @info="retourSearch" :value="zone" v-if="zone.length > 0 && selected !== zone"/>
      </div>
    </div>
  </div>
</template>

<script>
import inputo from '@/components/helper/form/input'
import selectListe from '@/components/helper/add/selectListe'
import localite from '@/components/cedro/jsonWord/locality.json'

export default {
  name: 'index',
  components: {
    inputo,
    selectListe
  },

  props: {
    donnes: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      zone: '',
      allZone: [],
      selected: null
    }
  },

  created () {
    this.trackPays()
    if (this.donnes.zone !== undefined) {
      this.zone = this.donnes.zone
      this.selected = this.donnes.zone
    }
  },

  methods: {
    retourInfo (answer) {
      this.zone = answer
    },

    retourSearch (answer) {
      this.selected = answer
      this.zone = answer
      this.$emit('info', answer)
    },

    trackPays () {
      for (const item in localite) {
        this.allZone.push(localite[item].label)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/style/sass/variables";
.input{
  background: #FFFFFF;
  border: 1px solid #E8E8E8;
  box-sizing: border-box;
  border-radius: 15.2377px;
  height: 70.09px;
}

div{
  font-family: $font-default;
}

.tiret{
  font-weight: 500;
  font-size: 20px;
  line-height: 34px;
  color: #A9B2C6;
  letter-spacing: 8.5px;
}

input, .neutre{
  font-weight: 500;
  font-size: 20px;
  line-height: 34px;
  color: #A9B2C6;
  letter-spacing: 4px;
}
.bas{
  margin-top: -10px;
}
.lettre{
  width: 8%;
}
.numero{
  width: 15%;
}
.sel{
  width: 99%;
}
</style>
