<template>
  <div class="w-full h-full">
    <popupToutRisque
      :want-to-make-offer="activeTout"
      @oga="retourRisque" v-if="position === allInfo[1] || positionCouv === couverture[1] || positionOld === allOld[0] && positionCouv === couverture[1]"
    />
    <div
      class="flex justify-center mt-5"
    >
      <div
        class="ml-2 mr-4 mb-5 w-full pl-2 "
      >
        <div
          v-for="(item, index) in allInfo"
          :key="index"
          class="w-full mb-3"
        >
          <radio
            v-if="index < 5"
            :index="item"
            :active="position"
            @radioStat="retourInfo"
            class="w-full"
          />
        </div>

        <div v-if="position === allInfo[0]">
          <div class="titre mt-6">Âge du véhicule</div>

          <div class="age mt-4">Depuis l’année de sortie d’usine ex: Hunday Sonata {{ annee }} ==> {{ age }} ans</div>
          <div
            class="w-full mb-3 md:flex mt-4"
          >
            <radio
              :index="allOld[0]"
              :active="positionOld"
              @radioStat="retourOld"
              class="md:w-1/2 w-full md:mr-5 md:mb-0 mb-4"
            />
            <radio
              :index="allOld[1]"
              :active="positionOld"
              @radioStat="retourOld"
              class="md:w-1/2 w-full ml-0"
            />
          </div>
        </div>

        <div v-if="positionOld === allOld[1] || positionOld === allOld[0]">
          <div class="titre mt-6">Quelle couverture souhaite-vous ?</div>

          <div
            class="w-full mb-3 md:flex mt-4"
          >
            <radio
              :index="couverture[0]"
              :active="positionCouv"
              @radioStat="retourCouv"
              class="md:w-1/2 w-full md:mr-5 md:mb-0 mb-4"
            />
            <radio
              :index="couverture[1]"
              :active="positionCouv"
              @radioStat="retourCouv"
              class="md:w-1/2 w-full ml-0"
            />
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import radio from '@/components/helper/form/radioAndLabel'
import popupToutRisque from '@/components/popup/popupToutRisque'

export default {
  name: 'index',
  components: {
    radio,
    popupToutRisque
  },
  props: {
    donnes: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      allInfo: ['Automobile personnelle', 'Autres (Taxi, bus...)'],
      allOld: ['Moins de 5 ans', 'Plus de 5ans'],
      couverture: ['Responsabilité Civile', 'Tous risques'],
      position: '',
      positionOld: '',
      positionCouv: '',
      annee: 2013,
      activeTout: true
    }
  },

  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    age: function () {
      // eslint-disable-next-line no-mixed-operators
      if (this.position === this.allInfo[0] && this.positionCouv === this.couverture[0] && this.positionOld === this.allOld[0] || this.positionOld === this.allOld[1]) {
        const obj = {
          categorie: this.position,
          old: this.positionOld,
          couverture: this.positionCouv
        }
        this.$emit('info', obj)
      }

      return new Date().getFullYear() - this.annee
    }
  },

  methods: {
    retourInfo (answer) {
      if (answer !== 'Autres') {
        this.position = answer
        this.$emit('info', answer)
      }
    },
    retourRisque (answer) {
      this.position = ''
      this.positionOld = ''
      this.positionCouv = ''
    },
    retourOld (answer) {
      this.positionOld = answer
    },
    retourCouv (answer) {
      this.positionCouv = answer
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/style/sass/variables";
.titre{
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 64px;
  text-align: center;
  color: #000000;
}
.age{
  font-size: 17.7655px;
  line-height: 27px;
  text-align: center;
  color: #404040;
}
@media screen and (max-width: 1024px) and (min-width: 770px){
}

@media screen and (max-width: 769px) and (min-width: 701px){
}

@media screen and (max-width: 700px) {
  .titre{
    font-size: 24px;
    line-height: 32px;
  }
  .textCharge{
    font-size: 20px;
  }
}

</style>
