<template>
  <div class="w-full h-full all pt-4">
    <div class="sous-titre md:w-2/3 mr-auto ml-auto text-center">Votre voiture a-t-elle moins de 5 ans ?  Si oui, souhaitez-vous une assurance tous risques ? </div>

    <div
      class="flex justify-center mt-6 w-full pl-4 pr-4"
    >
      <div class="button mr-5" @click="selected('oui')" :class="{'non': position === 'oui'}">Oui</div>
      <div class="button" @click="selected('non')" :class="{'non': position === 'non'}">Non</div>
    </div>
    <div v-if="position === 'oui'">
      <div class="sous-titre w-full mt-6">
        Valeur d'achat ?
      </div>

      <inputo type="number" placeholder="9" :value="valeur" label="FCFA" @info="retourInfo" class="mt-3"/>
    </div>
    <div class="sous-titre w-full mt-4">
      Numero chassis
    </div>
    <inputo placeholder="938834834348" :value="chassi" @info="retourChassi" class="mt-3"/>

    <div class="sous-titre w-full mt-4">
      Date de mise en circulation
    </div>
    <datePicker class="mt-3" deseable="['afterToday']" :value="date" @info="retourDate"/>

    <div class="sous-titre w-full mt-4">
      Date d’effet du contrat
    </div>
    <datePicker class="mt-3" :deseable="['beforeToday', new Date().toLocaleDateString()]" :value="souscriptionDate" @info="retourSouscription"/>

    {{donnee}}
  </div>
</template>

<script>
import inputo from '@/components/helper/form/input'
import datePicker from '@/components/helper/form/datePicker'

export default {
  name: 'index',
  components: {
    inputo,
    datePicker
  },

  props: {
    donnes: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      position: 'oui',
      valeur: null,
      date: null,
      chassi: null,
      souscriptionDate: null
    }
  },

  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    donnee: function () {
      if (this.position === 'oui' && this.date !== null && this.chassi !== null && this.souscriptionDate !== null) {
        if (this.valeur !== null) {
          const obj = {
            toutRisque: true,
            valeurVehicule: this.valeur,
            dateMiseEnCirculation: new Date(this.date).getTime(),
            chassi: this.chassi,
            souscriptionDate: new Date(this.souscriptionDate).getTime()
          }
          this.$emit('info', obj)
        }
      } else {
        if (this.date !== null && this.chassi !== null && this.souscriptionDate !== null) {
          const obj = {
            toutRisque: false,
            valeurVehicule: 0,
            dateMiseEnCirculation: new Date(this.date).getTime(),
            chassi: this.chassi,
            souscriptionDate: new Date(this.souscriptionDate).getTime()
          }
          this.$emit('info', obj)
        }
      }
    }
  },

  mounted () {
    console.log(this.donnes)
    if (this.donnes.valeur !== undefined) {
      if (!this.donnes.valeur.toutRisque) {
        this.position = 'non'
      }
      this.chassi = this.donnes.valeur.chassi
      this.valeur = this.donnes.valeur.valeurVehicule
      this.date = new Date(this.donnes.valeur.dateMiseEnCirculation).toLocaleDateString()
      this.souscriptionDate = new Date(this.donnes.valeur.souscriptionDate).toLocaleDateString()
    }
  },

  methods: {
    selected (index) {
      this.position = index
    },

    retourInfo (answer) {
      this.valeur = answer
    },

    retourDate (answer) {
      this.date = answer
    },
    retourChassi (answer) {
      this.chassi = answer.toUpperCase()
    },
    retourSouscription (answer) {
      this.souscriptionDate = answer
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/style/sass/variables";
.sous-titre{
  font-family: 'Mulish', sans-serif;;
  font-style: normal;
  font-weight: normal;
  font-size: 17.7655px;
  line-height: 27px;
  color: #000000;
}
.button{
  background: #E8E8E8;
  border: 1px solid #E8E8E8;
  box-sizing: border-box;
  border-radius: 20px;
  height: 50px;
  width: 15%;
  font-weight: normal;
  font-size: 17.7655px;
  line-height: 27px;
  color: black;
}
.non{
  background-color: $base-color;
  color: white;
}
.button:hover{
  background-color: $focus-color;
  color: white;
}
.alerte{
  height: 86px;
  border-radius: 20px;
  background-color: #F6E4E5;
  font-weight: normal;
  font-size: 18px;
  line-height: 27px;
  color: black;
}
@media screen and (max-width: 700px){
  .sous-titre{
    font-size: 16px;
  }
  .button{
    width: 30%;
    font-size: 17px;
  }
}
</style>
