<template>
  <div class="w-full h-full">
    <div
      class="flex justify-center mt-12"
    >
      <div
        class="ml-2 mr-4 mb-5 w-full pl-2"
      >
        <div class="sous-titre md:w-1/3 mb-3">
          Numéro du permis
        </div>
        <inputo :value="numero" placeholder="Ex: 09847" @info="retourInfo"/>
      </div>
    </div>

    <div
      class="flex justify-center"
    >
      <div
        class="ml-2 mr-4 mb-5 w-full pl-2"
      >
        <div class="sous-titre md:w-1/3 mb-3">
          Ajouter une photo du permis
        </div>

        <upload
          :placeholder="picture"
          class="mt-4"
          @info="retourFile"
        />
      </div>
    </div>
  </div>
</template>

<script>
import inputo from '@/components/helper/form/input'
// import datePicker from '@/components/helper/form/datePicker'
import upload from '@/components/helper/form/upload'

export default {
  name: 'index',
  components: {
    inputo,
    // datePicker,
    upload
  },

  props: {
    donnes: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      permis: {},
      numero: null,
      picture: 'Cliquez ici pour ajouter l’image'
    }
  },

  mounted () {
    if (this.donnes.permis !== undefined) {
      this.numero = this.donnes.permis.numero
      this.picture = this.donnes.permis.picture.name
    }
  },

  methods: {
    retourInfo (answer) {
      if (answer) {
        this.permis.numero = answer
        this.numero = answer.toUpperCase()
        this.send()
      }
    },

    retourFile (answer) {
      if (answer) {
        this.permis.picture = answer
        this.picture = answer.name
        this.send()
      }
    },

    send () {
      if (this.permis.numero !== undefined && this.permis.picture !== undefined) {
        this.$emit('info', this.permis)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/style/sass/variables";
.input{
  background: #FFFFFF;
  border: 1px solid #E8E8E8;
  box-sizing: border-box;
  border-radius: 15.2377px;
  height: 70.09px;
}

div{
  font-family: $font-default;
}

.tiret{
  font-weight: 500;
  font-size: 20px;
  line-height: 34px;
  color: #A9B2C6;
  letter-spacing: 8.5px;
}

input, .neutre{
  font-weight: 500;
  font-size: 20px;
  line-height: 34px;
  color: #A9B2C6;
  letter-spacing: 4px;
}
.bas{
  margin-top: -10px;
}
.lettre{
  width: 8%;
}
.numero{
  width: 15%;
}
.sel{
  width: 99%;
}
</style>
