<template>
  <div class="w-full h-full">
    <div
      class="flex justify-center mt-12"
    >
      <div
        class="md:flex flex-wrap ml-2 mr-4 mb-5 w-full pl-2 justify-center"
      >
        <div
          v-for="(item, index) in allInfo"
          :key="index"
          class=" mb-3 md:ml-1 md:mr-1 unit"
        >
          <radio
            :index="item"
            :active="position"
            @radioStat="retourInfo"
            class="w-full"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import radio from '@/components/helper/form/radioAndLabel'
import status from '../jsonWord/social-status.json'

export default {
  name: 'index',
  components: {
    radio
  },
  props: {
    donnes: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      allInfo: [],
      position: 'Agents commerciaux'
    }
  },

  created () {
    this.trackStatus()
  },

  mounted () {
    if (this.donnes.socioStatus !== undefined) {
      this.position = this.donnes.socioStatus
    }
    this.$emit('info', this.position)
  },

  methods: {
    retourInfo (answer) {
      this.position = answer
      this.$emit('info', answer)
    },
    trackStatus () {
      for (const item in status) {
        this.allInfo.push(status[item].label)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/style/sass/variables";
.sel{
  width: 99%;
}
.unit{
  width: 48%;
}
@media screen and (max-width: 700px) {
  .unit{
    width: 100%;
  }
}
</style>
