<template>
  <div class="w-full h-full all pt-2">
    <div class="sous-titre md:w-2/3 w-full mr-auto ml-auto">
    La protection du conducteur vous couvre personnellement en tant que conducteur. Les frais de soins sont illimités en cas de sinistres.
    </div>
<!--    <div class="sous-titre md:w-1/3 w-2/3 mr-auto ml-auto mt-3">Voulez-vous une couverture chauffeur illimitée  ? </div>-->
<!--    <div-->
<!--      class="flex justify-center mt-5 w-full pl-4 pr-4"-->
<!--    >-->
<!--      <div class="button mr-5" :class="{'non': position === 'oui' }" @click="activer('oui')">Oui</div>-->
<!--      <div class="button" :class="{'non': position === 'non' }" @click="activer('non')">Non</div>-->
<!--    </div>-->

    <div
      class="flex justify-center mt-5 pt-2 w-full md:pl-4 md:pr-4"
      v-if="position === 'non'"
    >
      <div class="alerte p-4 flex justify-center">
        <div class="w-4/5 text-center"> 👉🏾 Vous avez une couverture limitée  ⚠️</div>
      </div>
    </div>

    <div
      class="flex justify-center mt-5 pt-2 w-full md:pl-4 md:pr-4"
      v-if="position === 'oui'"

    >
      <div class="alerte p-4 flex justify-center" :class="{'gree': position === 'oui' }">
        <div class="w-4/5 text-center"> 👉🏾 Vous avez une couverture illimitée  ⚠️</div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'index',
  components: {
  },

  props: {
    donnes: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      position: 'oui'
    }
  },

  mounted () {
    if (this.donnes.protection !== undefined) {
      this.position = this.donnes.protection
    }
    this.$emit('info', this.position)
  },

  methods: {
    activer (index) {
      this.position = index
      this.$emit('info', index)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/style/sass/variables";
.sous-titre{
  font-family: 'Mulish', sans-serif;;
  font-style: normal;
  font-weight: normal;
  font-size: 17.7655px;
  line-height: 27px;
  text-align: center;
  color: #000000;
}
.button{
  background: #E8E8E8;
  border: 1px solid #E8E8E8;
  box-sizing: border-box;
  border-radius: 20px;
  height: 50px;
  width: 15%;
  font-weight: normal;
  font-size: 17.7655px;
  line-height: 27px;
  color: black;
}
.button:hover{
  border: 1px solid $focus-color;
}
.non{
  background-color: $base-color;
  color: white;
}
.alerte{
  height: 86px;
  border-radius: 20px;
  background-color: #F6E4E5;
  font-weight: normal;
  font-size: 18px;
  line-height: 27px;
  color: black;
}
.gree {
  background-color: $base-green;
  color: white;
}
@media screen and (max-width: 700px) {
  .button{
    width: 25%;
  }
}
</style>
