<template>
  <div class="w-full h-full">
    <div
      class="flex justify-center mt-12"
    >
      <div
        class="md:flex ml-2 mr-4 mb-5 w-full pl-1 justify-center"
      >
        <div
          v-for="(item, index) in allInfo"
          :key="index"
          class=" mb-3 md:ml-1 md:mr-1 md:w-1/2"
        >
          <radio
            :index="item"
            :active="position"
            @radioStat="retourInfo"
            class="w-full"
          />
        </div>
      </div>

    </div>

    <div class="titre mt-2">Date de délivrance de votre permis de conduire</div>
    <datePicker class="md:mt-3 mt-5" :deseable="['afterToday']" :value="date" @info="retourDate"/>

    <div class="titre mt-5">Durée de la couverture</div>
    <div
      class="md:flex md:ml-2 md:mr-4 mb-5 w-full md:pl-1 mt-4 justify-center"
    >
      <div
        v-for="(item, index) in allDure"
        :key="index"
        class="mb-3 md:ml-1 md:mr-1 md:w-1/2 w-full"
      >
        <radio
          :index="item"
          :active="positionDure"
          @radioStat="retourDure"
          class="w-full"
        />
      </div>
    </div>
  </div>
</template>

<script>
import radio from '@/components/helper/form/radioAndLabel'
import datePicker from '@/components/helper/form/datePicker'

export default {
  name: 'index',
  components: {
    radio,
    datePicker
  },
  props: {
    donnes: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      allInfo: ['Oui', 'Non'],
      position: 'Oui',
      allDure: ['12 Mois', '6 Mois'],
      positionDure: '12 Mois',
      date: null
    }
  },

  mounted () {
    if (this.donnes.oldAssur !== undefined) {
      this.position = this.donnes.oldAssur
    }
    if (this.donnes.permisDate !== undefined) {
      this.date = this.donnes.permisDate
    }
    this.$emit('info', this.position)
    this.$emit('dure', this.positionDure)
  },

  methods: {
    retourDure (answer) {
      this.positionDure = answer
      this.$emit('dure', answer)
    },
    retourInfo (answer) {
      this.position = answer
      this.$emit('info', answer)
    },
    retourDate (answer) {
      this.$emit('date', answer)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/style/sass/variables";
.sel{
  width: 99%;
}
.titre{
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 64px;
  text-align: center;
  color: #000000;
}
@media screen and (max-width: 700px) {
  .titre{
    font-size: 24px;
    line-height: 32px;
  }
}
</style>
